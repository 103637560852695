import './workcard.css';


function WorkCard({thumbnail, title, description, link}){
    return (
        <div className="cardContainer">
        <img src={thumbnail} alt="thumbnail" id="caseStudyCard"></img>
    <div className="cardContent">
        <h3 className="title">{title}</h3>
        <p className="Description">{description}</p>
        <button className="caseStudyBtn">View case study</button>
    </div>
  

    </div>

    )
}

export default WorkCard;
 