// import { Helmet } from 'react-helmet-async';

import logo from './logo.svg';
import './App.css';
import Homepage from './Components/homepage';
import DocumentMeta from 'react-document-meta';


function App() {
  const metaData = {
    title: 'Portfolio - Shubham Bhatt',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'shubham bhatt, portfolio'
      },
      description: 'Portfolio of Shubham Bhatt',
      viewport: 'width=device-width, initial-scale=1.0',
      robots: 'index, follow', // Controls search engine indexing behavior
      og: { // Open Graph metadata
        title: 'Portfolio of Shubham Bhatt',
        description: 'Welcome to my digital abode.',
        type: 'website',
        url: 'https://www.shubhambhatt.com',
        image: 'https://www.example.com/image.jpg',
        site_name: 'Shubham Bhatt - Portfolio'
      },
      twitter: { // Twitter Card metadata
        card: 'summary_large_image',
        site: '@example',
        title: 'Shubham Bhatt - Portfolio',
        description: 'Welcome to my digital abode.',
        image: 'https://www.example.com/image.jpg'
      }
    }
  };
 return (
    <div className="App">
       <DocumentMeta {...metaData} />
      <Homepage />
    </div>
  );
}

export default App;

