// import { useState } from "react";
import caseStudy1 from '../assets/images/caseStudy1.png';

import React, { useState } from 'react';
import './tabs.css';
import WorkCard from './workcard'
import BlogPost from './writing';

function TabbedInterface() {
  const [activeTab, setActiveTab] = useState('Work'); // State to track active tab

  // Function to handle tab click
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="tab-container">
      {/* Tab buttons */}
      <div className="tab-buttons">
        <button className={activeTab === 'Work' ? 'active' : 'inactive'} onClick={() => handleTabClick('Work')}>Work</button>
        <button className={activeTab === 'Side projects' ? 'active' : 'inactive'} onClick={() => handleTabClick('Side projects')}>Side projects</button>
        <button className={activeTab === 'Writing' ? 'active' : 'inactive'} onClick={() => handleTabClick('Writing')}>Writing</button>
        {/* <button className={activeTab === 'Everything else' ? 'active' : 'inactive'} onClick={() => handleTabClick('Everything else')}>Everything else</button> */}
      </div>
      
      {/* Content based on active tab */}
      <div className="tab-content">
        {activeTab === 'Work' && <WorkContent />}
        {activeTab === 'Side projects' && <SideProjectsContent />}
        {activeTab === 'Writing' && <WritingContent />}
        {/* {activeTab === 'Everything else' && <EverythingElseContent />} */}
      </div>
    </div>
  );
}

// Content components for each tab
function WorkContent() {
  return <div>
    <WorkCard 
      title="How we achieved ~1.8x GMV growth?"
      description="Feature that incentivises shopkeepers and retailers for accomplishing targets and hence increasing the GMV"
      thumbnail={caseStudy1} />

{/* <WorkCard 
      title="cdvdvvd"
      description="efefefe"
      thumbnail={caseStudy1} />

<WorkCard 
      title="cdvdvvd"
      description="efefefe"
      thumbnail={caseStudy1} /> */}
    </div>;
}

function SideProjectsContent() {
  return <div>Side Projects Content</div>;
}

function WritingContent() {
  return <div>

<BlogPost
        date="DECEMBER 31, 2023"
        title="All about 2023: Minor inconveniences of a privileged life"
        link="https://medium.com/@shubham-bhatt/all-about-2023-minor-inconveniences-of-a-privileged-life-14197ef7c3fb"
      />

<BlogPost
        date="DECEMBER 31, 2022"
        title="All about 2022: New Job, Tournament, & Anxiety"
        link="https://medium.com/@shubham-bhatt/all-about-2022-new-job-tournament-anxiety-12c55d089860"
      />

<BlogPost
        date="JULY 31, 2022"
        title="My Vipassana Experience: Path to Equanimity"
        link="https://medium.com/@shubham-bhatt/my-vipassana-experience-path-to-equanimity-4187a999a604"
      />

<BlogPost
        date="DECEMBER 31, 2021"
        title="All about 2021: Existentialism, Realizations & future plans"
        link="https://shubham-bhatt.medium.com/all-about-2021-existentialism-coffee-dates-realizations-e4fc0eef62f5"
      />
  </div>;
}

// function EverythingElseContent() {
//   return <div>Everything Else Content</div>;
// }

export default TabbedInterface;
