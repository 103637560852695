import { Download } from "@phosphor-icons/react";
import portrait from '../assets/images/portrait.jpeg';
import TabbedInterface from "./tabs";


function HeroImage() {
    return (
        <>
            <img id="portrait" src={portrait} alt="Portrait"></img>
        </>
    )
}

function HeroText() {
    return (
        <div id="heroText">
            <h2 id="heroText">Hi, my name is Shubham Bhatt. I am a tinkerer and business focused product designer</h2>
            <p id="subtext">I am looking for 0-1 opportunities, how might we connect if you are building something interesting?</p>
        </div>
    )
}

function ResumeBtn() {
    return (
            <button className="iconContainer button">
                 <Download size={20} className="bold-icon" /> Download resume
            </button>
    )
}

function AvailabilityTag() {
    return (
        <div className="availability-container">
            <div className="tag">
                <span className="blinking-symbol"></span>
                <p>available for hire</p>
            </div>
        </div>
    );
}

export default function Homepage() {
    return (
        <div className="container">
            <div id="heroSection">

                <HeroImage />
                <AvailabilityTag />
                <HeroText />
                <ResumeBtn />
            </div>

            <div id="tabSection">
                <TabbedInterface />
            </div>
        </div>


    )

}