import './writing.css';

function BlogPost({date, title, link}) {
    return (
        <div className="blog-post">
            <div className="blog-date">{date}</div>
            <a href={link} className="blog-title" target="_blank" rel="noopener noreferrer">
        {title}
      </a>
            {/* <p className="blog-subtitle">{subtitle}</p> */}
        </div>
    )
}

export default BlogPost;
